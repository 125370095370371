import styled from 'styled-components';
import { Colors, Fonts, Media } from '../lib/theme';

export const H2 = styled.h2`
    margin: 0 0 10px;
    line-height: 120%;
    font-size: 20px;
    color: ${Colors.primary};
    font-family: ${Fonts.bold};
    font-weight: bold;

    @media ${Media.LG} {
        font-size: 26px;
        margin-bottom: 20px;
    }
`

export const H3 = styled.h3`
    margin: 0 0 10px;
    line-height: 120%;
    font-size: 16px;
    color: ${Colors.primary};
    font-weight: bold;

    @media ${Media.LG} {
        font-size: 19px;
        margin-bottom: 15px;
    }
`

export const Important = styled.strong<{primary?: boolean}>`
    display: inline-block;
    color: ${props => props.primary ? Colors.primary : 'inherit'};
    font-weight: bold;
`

export const SectionTitle = styled.div`
    margin-bottom: 20px;
    padding: 15px 20px;
    background: ${Colors.primary};
    color: #fff;
    font-size: 18px;
    font-weight: bold;

    @media ${Media.LG} {
        font-size: 21px;
    }
`

export const List = styled.ul`
    margin: 0;
    padding: 0;
    list-style: none;

    li {
        padding: 0 0 0 10px;

        &::before {
            margin: 0 0 0 -10px;
            content: "• ";
            color: ${Colors.primary};
        }
    }
`

export const OrderedList = styled.ol`
    list-style: none;
    counter-reset: ordered-list;
    margin: 0;
    padding: 0;

    li {
        margin: 0 0 20px;
    }

    > li {
        position: relative;
        counter-increment: ordered-list;
        padding: 0 0 0 30px;

        &::before {
            position: absolute;
            left: 0;
            content: counter(ordered-list) ".";
            color: ${Colors.primary};
            font-weight: bold;
        }
    }

`