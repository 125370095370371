import { BaseContainer, PageWrapper } from '../components/Containers';
import { H2 } from '../components/Typo';

export default function Done() {
    return (
        <> 
            <PageWrapper>
                <BaseContainer>
                    <H2>
                        Vielen Dank für Ihre Teilnahme!
                    </H2>

                    <p>
                        Unsere Aktion ist beendet. Wir sagen Danke fürs Teilnehmen, gratulieren allen EmpfängerInnen eines 25 € Zalando-Gutscheins und wünschen weiterhin viel Freude beim sicheren Online-Shoppen mit Ihrer Sparkassen-Card!
                        <br /><br />
                        Ihr Aktionsteam der Sparkasse Vorpommern
                    </p>
                </BaseContainer>
            </PageWrapper>
        </>
    )
}