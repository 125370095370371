import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { BaseContainer, PageWrapper } from '../components/Containers';
import { Hero } from '../components/Hero';
import { H2 } from '../components/Typo';
import { HOST_URL } from '../lib/routes';

export default function Doi() {
    const {email, key} = useParams<{email: string, key: string}>();
    const [state, setState] = useState<'success' | 'already' | 'error' | null>(null);

    useEffect(() => {
        if (email === undefined || key === undefined) {
            setState('error');
            return;
        }

        (async () => {
            const body = new FormData();
            body.append('email', email);
            body.append('key', key);

            const rawResponse = await fetch(`${HOST_URL}/api/v1/participant/confirm`, {body, method: 'POST'});
            if (rawResponse.ok) {
                setState('success');
                return;
            }

            const textResponse = await rawResponse.text();
            if (textResponse.includes('already')) {
                setState('already')
                return;
            }

            setState('error');
        })();
    }, [email, key]);


    return (
        <>
            <Hero />

            <PageWrapper>
                <BaseContainer>
                    {state === null && (<>
                        <H2>Ihre Teilnahme</H2>
                        <p>
                            Bitte warten Sie einen Moment, während wir Ihre Gewinnspielteilnahme abschließen.
                        </p>
                    </>)}

                    {state === 'success' && (<>
                        <H2>Anmeldung abgeschlossen!</H2>
                        <p>
                            Wunderbar, Sie haben Ihre Teilnahme bestätigt. Ihre Anmeldung zur Aktion ist erfolgreich gespeichert.<br />
                            Die ersten 1.000 Teilnehmer erhalten nach Aktionsende per E-Mail von aktion@mc-spk-vorpommern.de einen 25 € Zalando-Gutschein.
                            <br /><br />
                            Wir drücken Ihnen die Daumen und wünschen Ihnen viel Freude beim sicheren Online-Shopping mit Ihrer Sparkassen-Card!
                        </p>
                    </>)}

                    {state === 'already' && (<>
                        <H2>Anmeldung bereits abgeschlossen!</H2>
                        <p>
                            Sie haben Ihre Teilnahme zu einem früheren Zeitpunkt bereits bestätigt. Ihre Anmeldung zur Aktion ist erfolgreich gespeichert. Wir wünschen Ihnen viel Glück!
                        </p>
                    </>)}

                    {state === 'error' && (<>
                        <H2>Ihre Teilnahme</H2>
                        <p>
                            Leider konnten wir Ihre Gewinnspielteilnahme nicht abschließen. Bitte prüfen Sie die URL oder versuchen Sie es erneut.
                        </p>
                    </>)}
                </BaseContainer>
            </PageWrapper>
        </>
    )
}
