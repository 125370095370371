/* eslint-disable react/jsx-no-target-blank */
import styled from 'styled-components';
import { BaseContainer, PageWrapper } from '../components/Containers';
import { H2 } from '../components/Typo';

const Table = styled.table`
    width: 100%;
    border-spacing: 0;
    border-collapse: collapse;
    border: 0;

    tr {
        &:nth-child(odd) {
            background: #fff;
        }

        &:nth-child(even) {
            background: #f0f0f0;
        }
    }

    td,
    th {
        padding: 15px 10px;
        text-align: left;
    }
`;

export default function Imprint() {

    return (
        <PageWrapper className="py-40">
            <H2>Impressum</H2>

            <BaseContainer className="mt-40">
                Herausgeber:
                <br /><br />
                Sparkasse Vorpommern<br />
                Anstalt Öffentlichen Rechts<br />
                An der Sparkasse 1<br />
                17489 Greifswald
                <br /><br />

                <Table>
                    <tr>
                        <th>
                            Bankleitzahl
                        </th>
                        <td>
                            15050500
                        </td>
                    </tr>    
                    <tr>
                        <th>
                            BIC
                        </th>
                        <td>
                            NOLADE21GRW
                        </td>
                    </tr>    
                    <tr>
                        <th>
                            Handelsregisternummer
                        </th>
                        <td>
                            Handelsregister Stralsund HRA 1291
                        </td>
                    </tr>    
                    <tr>
                        <th>
                            Umsatzsteuer-Identifikations-<br />
                            nummer nach §27 a UStG
                        </th>
                        <td>
                            DE 811 671 292
                        </td>
                    </tr>    
                    <tr>
                        <th>
                            Vorsitzende(r) des Vorstandes
                        </th>
                        <td>
                            Ulrich Wolff
                        </td>
                    </tr>    
                    <tr>
                        <th>
                            Mitglied(er) des Vorstandes
                        </th>
                        <td>
                            Thomas Metzke
                        </td>
                    </tr>    
                    <tr>
                        <th>
                            Telefon
                        </th>
                        <td>
                            03834 55 77 888
                        </td>
                    </tr>    
                    <tr>
                        <th>
                            Fax
                        </th>
                        <td>
                            03834 55 77 239
                        </td>
                    </tr>    
                    <tr>
                        <th>
                            E-Mail
                        </th>
                        <td>
                            info@spk-vorpommern.de
                        </td>
                    </tr>
                    <tr>
                        <th>
                            zuständige Aufsichtsbehörde für die Zulassung
                        </th>
                        <td>
                            Europäische Zentralbank<br />
                            Sonnemannstraße 20<br />
                            60314 Frankfurt am Main<br />
                            <a href="https://www.ecb.europa.eu" target="_blank">www.ecb.europa.eu</a>
                        </td>
                    </tr>
                    <tr>
                        <th>
                            zuständige Aufsichtsbehörde für den Verbraucherschutz
                        </th>
                        <td>
                            Bundesanstalt für Finanzdienstleistungsaufsicht<br />
                            Graurheindorfer Str. 108<br />
                            53117 Bonn<br />
                            und<br />
                            Marie-Curie-Str. 24 - 28<br />
                            60439 Frankfurt am Main<br />
                            <a href="https://www.bafin.de" target="_blank">www.bafin.de</a>
                        </td>
                    </tr>
                    <tr>
                        <th>
                            Schlichtungsstelle für Sparkassenkunden
                        </th>
                        <td>
                            Bei Streitigkeiten mit der Ihrer Sparkasse haben Sie die Möglichkeit, sich an die Schlichtungsstelle des Deutschen Sparkassen- und Giroverbandes zuwenden. Das Anliegen ist in Textform an die folgende Adresse zu richten:
                            <br /><br />
                            Deutscher Sparkassen- und Giroverband e.V.<br />
                            Schlichtungsstelle<br />
                            Charlottenstraße 47<br />
                            10117 Berlin
                            <br /><br />
                            Online erreichen Sie den Deutschen Sparkassen- und Giroverband (DSGV) unter:<br />
                            <a href="https://www.dsgv.de/de/ueber-uns/schlichtungsstelle/index.html" target="_blank">www.dsgv.de/de/ueber-uns/schlichtungsstelle/index.html</a>
                            <br /><br />
                            Näheres regelt die "Verfahrensordnung für die außerordentliche Schlichtung von Kundenbeschwerden für die Institute der Sparkassen Finanzgruppe", die auf Wunsch zur Verfügung gestellt wird.
                            <br /><br />
                            Die Sparkasse Vorpommern nimmt am Streitbeilegungsverfahren vor dieser anerkannten Verbraucherschlichtungsstelle teil.
                        </td>
                    </tr>
                    <tr>
                        <th>
                            Europäische Online-Streitbeilegungsplattform
                        </th>
                        <td>
                            Online-Streitbeilegung gemäß Art. 14 Abs. 1 ODR-VO:
                            <br /><br />
                            Die Europäische Kommission hat eine <a href="http://ec.europa.eu/odr" target="_blank">Online-Streitbeilegungsplattform</a> errichtet.
                            <br /><br />
                            Die Online-Streitbeilegungsplattform können Verbraucher für die außergerichtliche Beilegung von Streitigkeiten aus online abgeschlossenen Kauf- oder Dienstleistungsverträgen nutzen.  
                        </td>
                    </tr>
                </Table>
            </BaseContainer>
        </PageWrapper>
    )
}