import { Route, Routes, useLocation } from 'react-router-dom';
import PageIndex from '../pages/Index';
import PageDoi from '../pages/Doi';
import PageFinish from '../pages/Finish';
import PageImprint from '../pages/Imprint';
import PagePrivacy from '../pages/Privacy';
import PageTerms from '../pages/Terms';
import Page404 from '../pages/404';
import PageOver from '../pages/Over';
import { AppHeader } from './AppHeader';
import { AppFooter } from './AppFooter';
import { doiRoute, formSuccessRoute, HOST_URL, imprintRoute, privacyRoute, termsRoute } from '../lib/routes';
import { useEffect, useState } from 'react';

export const AppLayout = () => {
    const location = useLocation();
    const [currentPhase, setCurrentPhase] = useState<'over' | 'participation' | null>(null);

    useEffect(() => {
        if (currentPhase === null) {
            (async () => {
                const rawResponse = await fetch(`${HOST_URL}/api/v1/config`);
                if (!rawResponse.ok) {
                    return;
                }

                const res = await rawResponse.json();

                setCurrentPhase(res.config.phase);
            })()
        }

    }, [currentPhase])

    useEffect(() => {
        window.scrollTo(0,0);
    }, [location.pathname]);

    if (currentPhase === null) {
        return <>
            <AppHeader />
        </>;
    }

    return (
        <>
            <AppHeader />

            <Routes>
                {currentPhase === 'over' ?
                    <>
                        <Route path='/' element={<PageOver />} />
                    </>
                :
                    <>
                        <Route path='/' element={<PageIndex />} />
                        <Route path={formSuccessRoute()} element={<PageFinish />} />
                        <Route path={doiRoute()} element={<PageDoi />} />
                    </>
                }
                <Route path={imprintRoute()} element={<PageImprint />} />
                <Route path={termsRoute()} element={<PageTerms />} />
                <Route path={privacyRoute()} element={<PagePrivacy />} />
                <Route path='*' element={<Page404 />} />
            </Routes>

            <AppFooter />
        </>
    )
}