import { Link } from 'react-router-dom';
import { PageWrapper } from '../components/Containers';
import { H2, Important, List, OrderedList } from '../components/Typo';
import { privacyRoute } from '../lib/routes';

export default function Terms() {

    return (
        <PageWrapper className="py-40">
            <H2>Teilnahmebedingungen für die Onlineshopping-Aktion der Sparkasse Vorpommern zur Sparkassen-Card („Aktion“)</H2>

            <div className="mt-40">
                <OrderedList>
                    <li>
                        Die Aktion zur Sparkassen-Card wird durchgeführt von der Sparkasse Vorpommern, An der Sparkasse 1, 17489 Greifswald <Important>(„Sparkasse Vorpommern“ oder „Veranstalterin“)</Important>. Sie ist Veranstalterin der Aktion. Mit der Umsetzung der Aktion ist die im Auftrag der Sparkasse Vorpommern handelnde K+R GmbH, Ludwig-Erhard-Straße 6, 65760 Eschborn <Important>(„K+R“)</Important> beauftragt. Die Teilnahme an der Aktion richtet sich nach den nachstehend aufgeführten Bedingungen. Mit der Teilnahme an der Aktion erklären sich die Teilnehmer mit diesen einverstanden.
                    </li>

                    <li>
                        Die Aktion wird in der Zeit vom 01.10.2022, 00:00:01 Uhr bis 31.10.2022, 23:59:59 Uhr MEZ („Aktionszeitraum“) durchgeführt. Im Aktionszeitraum nimmt die Teilnehmerin/der Teilnehmer auf der aktionsbezogenen Website mc-spk-vorpommern.de teil durch:
                        <br /><br />
                        <List>
                            <li>
                                Angabe von Vor- und Zuname sowie ihrer/seiner E-Mail-Adresse sowie
                            </li>

                            <li>
                                Zustimmung zu den Teilnahmebedingungen und der Datenschutzerklärung.
                            </li>
                        </List>
                        Die Teilnehmerin/der Teilnehmer versichert mit der Absendung des Teilnahmeformulars zudem, dass:
                        <List>
                            <li>
                                ihre/seine Sparkassen-Card für das S-ID-Check-Verfahren registriert ist. Dafür sind das Herunterladen der S-ID-Check-App auf das mobile Endgerät sowie die Registrierung der Karte in der S-ID-Check-App erforderlich. Die Registrierung der Karte muss vor Teilnahme an der Aktion vollständig erfolgt sein.
                            </li>
                            <li>
                                sie/er im Aktionszeitraum nach der abgeschlossenen Registrierung in der S-ID-Check-App mindestens einmal online mit der Sparkassen-Card gezahlt hat.
                            </li>
                        </List>
                    </li>

                    <li>
                        Die unter Ziffer 2 genannten Bedingungen müssen erfüllt und die Anmeldung zur Aktion (durch Absenden des Teilnahmeformulars) muss innerhalb des o. g. Aktionszeitraums bei der Veranstalterin eingegangen sein.
                    </li>

                    <li>
                        Teilnahmeberechtigt ist jede Inhaberin/jeder Inhaber einer in der S-ID-Check-App (siehe Ziffer 2) registrierten und von der Sparkasse Vorpommern ausgegebenen Sparkassen-Card (Co-Badge Debit Mastercard®), die/der zum Zeitpunkt der Teilnahme das 18. Lebensjahr vollendet hat. Ausgeschlossen von der Teilnahme an der Aktion sind Mitarbeiter der Sparkasse, der K+R GmbH und Mastercard sowie deren Angehörige und von der Sparkasse Vorpommern, K+R bzw. Mastercard beauftragte Personen, Unternehmen und Dienstleister, einschließlich deren Mitarbeiter und Angehörige.
                    </li>

                    <li>
                        Gewinnspielagenten und Gewinnspielclubs sind von der Teilnahme an der Aktion ausgeschlossen. 
                    </li>

                    <li>
                        Die Sparkasse Vorpommern behält sich vor, die Einhaltung der Teilnahmebedingungen zu überprüfen. Im Falle eines Verstoßes gegen die Teilnahmebedingungen behält sich die Veranstalterin das Recht vor, Personen von der Teilnahme auszuschließen.
                    </li>

                    <li>
                        Eine Teilnehmerin/ein Teilnehmer nimmt an der Aktion teil, indem sie/er sich auf der Webseite gemäß Ziffern 2-3 registriert. Jede Inhaberin/jeder Inhaber einer Sparkassen-Card der Sparkasse Vorpommern kann sich für diese Aktion nur einmal registrieren. Im Falle einer Mehrfachregistrierung mit verschiedenen E-Mail-Adressen kann die Teilnehmerin/der Teilnehmer von der Teilnahme an dieser Aktion ausgeschlossen werden.
                    </li>

                    <li>
                        Die ersten 1.000 Teilnehmerinnen bzw. Teilnehmer, die während des Aktionszeitraums wie vorstehend unter den Ziffern 2 bis 3 beschrieben ihre Teilnahme bestätigt haben, erhalten einen von insgesamt 1.000 Zalando-Gutscheinen im Wert von jeweils 25,- Euro (First-Come-First-Serve-Prinzip).
                    </li>

                    <li>
                        Die Gutschein-Empfänger werden nach Aktionsende ermittelt und per E-Mail (aktion@mc-spk-vorpommern.de) benachrichtigt. Mit Versendung des Gutscheins (Übermittlung des Gutschein-Codes per E-Mail) sind die Pflichten der Veranstalterin in Bezug auf die Gutschein-Ausgabe erfüllt. Die Veranstalterin ist nicht verantwortlich für die Unzustellbarkeit von Gutscheinen, beispielsweise aufgrund von fehlerhaft angegebenen E-Mail-Adressen, unzureichenden Postfach-Speicherkapazitäten oder Systemeinstellungen von E-Mail-Diensten der Teilnehmenden.
                    </li>

                    <li>
                        Für die Gutscheine gelten die mit dem Gutschein-Code an die Teilnehmenden übermittelten Einlösebedingungen.
                    </li>

                    <li>
                        Es besteht keine Verpflichtung der Teilnehmenden zur Annahme eines Gutscheins. 
                    </li>

                    <li>
                        Die Teilnahme an der Aktion ist freiwillig. 
                    </li>

                    <li>
                        Die Veräußerung (auch im Rahmen von Online-Auktionen) sowie die Barauszahlung des Gutscheins oder eines eventuellen Restwerts sind ausgeschlossen. 
                    </li>

                    <li>
                        Die Veranstalterin behält sich vor, die Aktion zu jedem Zeitpunkt ohne Vorankündigung und ohne Angabe von Gründen einzustellen oder abzuändern, insbesondere wenn die ordnungsgemäße Durchführung aufgrund tatsächlicher oder rechtlicher Hindernisse nicht mehr gewährleistet ist. 
                    </li>

                    <li>
                        Personen, die versuchen, sich selbst oder Dritten bei der Teilnahme am Gewinnspiel durch Manipulation Vorteile zu verschaffen, können von der Teilnahme ausgeschlossen werden. Auf unberechtigte Weise erworbene Gutscheine können zurückgefordert werden. 
                    </li>

                    <li>
                        Die Veranstalterin haftet nur für vorsätzlich oder grob fahrlässige oder durch die Verletzung von wesentlichen Vertragspflichten verursachte Schäden unbeschränkt. Im Übrigen ist die Haftung der Veranstalterin ausgeschlossen. Diese Haftungsbeschränkung gilt nicht für Schäden durch die Verletzung von Leben, Körper und/oder Gesundheit. 
                    </li>

                    <li>
                        Personenbezogene Daten werden ausschließlich für die Teilnahme, die Durchführung und Abwicklung des Gewinnspiels unter Beachtung der gesetzlichen Bestimmungen gespeichert, genutzt und verarbeitet, siehe hierzu die <Link to={privacyRoute()}>Datenschutzerklärung</Link>. 
                    </li>

                    <li>
                        Der Rechtsweg ist ausgeschlossen. 
                    </li>

                    <li>
                        Es gilt ausschließlich das Recht der Bundesrepublik Deutschland. 
                    </li>

                    <li>
                        Sollten einzelne Bestimmungen dieser Teilnahmebedingungen ungültig sein, bleibt die Gültigkeit der übrigen Bedingungen hiervon unberührt. 
                    </li>
                </OrderedList>
            </div>
        </PageWrapper>
    )
}